import React from 'react';
import Button from "./Button";

const CallToAction = props => {
  return (
    <section className="cta" id="start">
      <h1>Are you a Distributor, Wholesaler, Farm, or Food Hub?</h1>
      <p>
        Supply Caravan offers a robust suite of tools for you to manage and fulfill your Orders.
      </p>
      <p>
        Sign up today and connect with your trading partners.
      </p>
      <a href="https://share.hsforms.com/1nrACVrECQdCOqylN9wbEgQ3tblf">
            <Button label="Get Started" />
      </a>
    </section>
  )
};

export default CallToAction;
