import React from 'react';

const Industries = props => {

  return (
    <section className="product-features" id="industries">
      <h1>Industries</h1>
      <div className="core-features">
        <div className="core-feature-1">
          <div className="industry produce">
          </div>
          <h2>Produce</h2>
        </div>
        <div className="core-feature-2">
          <div className="core-feature-1-image">
            <div className="industry seafood">
            </div>
          </div>
          <h2>Seafood</h2>
        </div>
        <div className="core-feature-3">
          <div className="core-feature-1-image">
            <div className="industry coffee"></div>
          </div>
          <h2>Coffee</h2>
        </div>
        <div className="core-feature-4">
          <div className="core-feature-3-image">
            <div className="industry meats"></div>
          </div>
          <h2>Meats</h2>
        </div>
        <div className="core-feature-5">
          <div className="core-feature-1-image">
            <div className="industry dairy"></div>
          </div>
          <h2>Dairy</h2>
        </div>
        <div className="core-feature-6">
          <div className="core-feature-1-image">
            <div className="industry grain"></div>
          </div>
          <h2>Grain</h2>
        </div>
      </div>
    </section>
  )
};

export default Industries;
