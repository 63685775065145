import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ValueProp from "../components/ValueProp";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import Industries from "../components/Industries";
import Testimonials from "../components/Testimonials";
import CallToAction from "../components/CallToAction";

const IndexPage = () => (
  <Layout>
    <Seo
      title="The Wholesale Ordering Software"
      keywords="b2b food marketplace, b2b ordering software, distributor mobile ordering, distributor order management, wholesale mobile ordering, wholesale ordering software, food distribution marketplace"
    />
    <ValueProp />
    <ProductBenefits />
    <ProductFeatures />
    <Industries />
    <Testimonials />
    <CallToAction />
  </Layout>
);

export default IndexPage;
